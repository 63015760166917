@charset "utf-8";
@import 'import/var';


/**************************
	drawer
*/

$drawer_width: 100%;
$drawer_li_top_height: 61px;
$drawer_li_height: 70px;
$drawer_color: #222;
$drawer_background-color: #f9f9f9;
$drawer_hover_background-color: #169296;
$drawer_hover_color: #fff;
$drawer_li_border_color: #ddd;
$drawer_li_border_width: 1px;
// $drawer_arrow_color: #ff6600;
$drawer_mm_next_bg_color: transparent;
$drawer_mm_next_arrow_color: #222;

$drawer_li_height: 50px;


/**************************
	drawer
*/

.mm-page {
	--mm-size: 0%;
	--mm-max-size: 0%;
}

.mm-menu--fullscreen.mm-menu--opened~.mm-slideout {
	--mm-max-size: 0%
}
body {
	&.mm-wrapper--opened {
		.root {
			.header {
				background-color: #fff;
			}
		}
	}
}

#drawer {
	width: 100%;
	top: 60px;
	z-index: 1000;
	.drawer {
		&__bottom {
			flex-direction: column !important;
		}
	}
}

.mm-navbar {
	height: 60px;
	border-bottom: 1px solid #728397;
	background: transparent;
	background: url("../img/sp/sp_menu_bg.webp");
	background-size: cover;
	&__title {
		justify-content: flex-start;
		padding-left: 0 !important;
		> span {
			color: #2c3746;
			font-size: $fontsize18;
		}
	}
	&__btn {
		width: 30px;
		&::before {
			width: 10px;
			height: 10px;
			border-top: 2px solid #54677f;
			border-left: 2px solid #54677f;
			top: 3px;
			left: 10px;
		}
	}
}

.mm-panel {
	background: url("../img/sp/sp_menu_bg.webp");
	background-size: contain;
	background-position: 0 0;
	padding: 0 20px;
	* {
		color: #475361;
	}
	&::after {
		display: none;
	}
}
#mm-1 {
	.mm-navbar {
		display: none;
	}
}
.mm-listitem {
	border-color: #fff;
	padding: 0;
	font-size: $fontsize16;
	&__text {
		&._foot {
			padding: 0;
		}
		&::after {
			border-color: #c2ccdd;
		}
	}
	&.li1, &.li2, &.li3, &.li4, &.li5 {
		.mm-listitem__text {
			padding-left: 30px;
			padding-top: 20px;
			padding-bottom: 20px;
			position: relative;
			&::before {
				display: block;
				content: "";
				width: 7px;
				height: 11px;
				background: url("../img/icon/arrow/sp_menu_arrow2.webp") no-repeat;
				background-size: contain;
				position: absolute;
				top: 0;
				right: auto;
				bottom: 0;
				left: 13px;
				margin: auto;
			}
		}
	}
	&__btn {
		&::after {
			border-top: 1px solid #566980;
			border-left: 1px solid #566980;
			width: 10px;
			height: 10px;
			left: 15px;
		}
	}
	&::after {
		border-bottom: 1px solid #c2ccdd;
		left: 0;
	}
}

.mm-listitem__btn:not(.mm-listitem__text) {
	border: none;
	padding-right: 50px;
	&::before {
		display: block;
		content: "";
		width: 1px;
		height: calc(100% - 20px);
		background-color: #c2ccdd;
		position: absolute;
		top: 0;
		right: auto;
		bottom: 0;
		left: 0;
		margin: auto;
		border: none;
	}
}

.mm-wrapper__blocker {
	display: none;
}

.mm-menu--offcanvas {
	transition: 0.3s cubic-bezier(.16,.96,.25,.91);
	&:not(.mm-menu--opened) {
		transform: translateX(100%);
	}
	&.mm-menu--opened {
		transform: translateX(0);
	}
}

.drawer__li {
	padding: 0;
}
.drawer__a {
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 30px;
	position: relative;
	&::before {
		display: block;
		content: "";
		width: 19px;
		height: 19px;
		background: url("../img/icon/arrow/sp_menu_arrow.webp") no-repeat;
		background-size: contain;
		position: absolute;
		top: 0;
		right: auto;
		bottom: 0;
		left: 1px;
		margin: auto;
	}
}

.sp_menu_icon_btn_area {
	margin-top: 20px;
	margin-bottom: 25px;
	> .head {
		padding-left: 10px;
		> .jp {
			font-size: $fontsize16;
			color: #304c70;
			margin-bottom: 5px;
		}
		> .en {
			font-size: $fontsize14;
			color: #304c70;
			@include source_serif;
		}
	}
	> .icon_list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		> .icon_btn {
			width: calc(50% - (20px / 2));
			padding-top: 18px;
			padding-bottom: 18px;
			&:nth-of-type(-n + 4) {
				border-bottom: 1px solid #cfd7e4;
			}
			.image {
				width: 60px;
				margin: 0 auto 5px;
			}
			.text {
				color: #475361;
				text-align: center;
				font-size: $fontsize14;
			}
		}
	}
}

.drawer__bottom {
	padding-bottom: 0;
	&::after {
		display: none;
	}
}
.drawer__search {
	background-color: #304c70;
	margin: 0 calc(50% - 50vw);
    width: 100vw;
	min-height: 120px;
	padding: 20px;
	> .search_input {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		row-gap: 15px;
		.link {
			color: #fff !important;
			&:before {
				background: url("../img/icon/arrow/link_arrow_white.webp") no-repeat;
				background-size: contain;
			}
		}
		.input {
			background-color: #223b5c !important;
			box-shadow: inset 3.536px 3.536px 10px 0px rgba(24, 47, 77, 0.5);
			padding-left: 30px;
			color: #fff !important;
			&::placeholder {
				color: #909cae;
			}
		}
		.submit_btn {
			display: block;
			width: 16px;
			height: 16px;
			background: url("../img/icon/search_gray.webp") no-repeat;
			background-size: contain;
			position: absolute;
			top: -2px;
			right: auto;
			bottom: 0;
			left: 8px;
			margin: auto;
			border: none;
			outline: none;
		}
	}
}

.drawer_language {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #cfd7e4;
	box-sizing: border-box;
	padding: 10px 0px 10px 0px;

	> .list {
		display: flex;
		width: 100%;

		.item {
			width: 50%;
			text-align: center;

			&:first-child {
				border-right: 1px solid #cfd7e4;
			}

			a {
				display: block;
				padding: 12px 20px;
			}

			.text {
				position: relative;
				padding-right: 18px;
				&::after {
					display: block;
					content: "";
					width: 6px;
					height: 9px;
					background: url('../img/icon/arrow/link2.webp');
					background-size: contain;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
				}
			}
		}
	}
}