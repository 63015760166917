/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
/**************************
	drawer
*/
/**************************
	drawer
*/
.mm-page {
	--mm-size: 0%;
	--mm-max-size: 0%;
}

.mm-menu--fullscreen.mm-menu--opened ~ .mm-slideout {
	--mm-max-size: 0% ;
}

body.mm-wrapper--opened .root .header {
	background-color: #fff;
}

#drawer {
	width: 100%;
	top: 60px;
	z-index: 1000;
}
#drawer .drawer__bottom {
	flex-direction: column !important;
}

.mm-navbar {
	height: 60px;
	border-bottom: 1px solid #728397;
	background: transparent;
	background: url("../img/sp/sp_menu_bg.webp");
	background-size: cover;
}
.mm-navbar__title {
	justify-content: flex-start;
	padding-left: 0 !important;
}
.mm-navbar__title > span {
	color: #2c3746;
	font-size: 1.125rem;
}
.mm-navbar__btn {
	width: 30px;
}
.mm-navbar__btn::before {
	width: 10px;
	height: 10px;
	border-top: 2px solid #54677f;
	border-left: 2px solid #54677f;
	top: 3px;
	left: 10px;
}

.mm-panel {
	background: url("../img/sp/sp_menu_bg.webp");
	background-size: contain;
	background-position: 0 0;
	padding: 0 20px;
}
.mm-panel * {
	color: #475361;
}
.mm-panel::after {
	display: none;
}

#mm-1 .mm-navbar {
	display: none;
}

.mm-listitem {
	border-color: #fff;
	padding: 0;
	font-size: 1rem;
}
.mm-listitem__text._foot {
	padding: 0;
}
.mm-listitem__text::after {
	border-color: #c2ccdd;
}
.mm-listitem.li1 .mm-listitem__text, .mm-listitem.li2 .mm-listitem__text, .mm-listitem.li3 .mm-listitem__text, .mm-listitem.li4 .mm-listitem__text, .mm-listitem.li5 .mm-listitem__text {
	padding-left: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
	position: relative;
}
.mm-listitem.li1 .mm-listitem__text::before, .mm-listitem.li2 .mm-listitem__text::before, .mm-listitem.li3 .mm-listitem__text::before, .mm-listitem.li4 .mm-listitem__text::before, .mm-listitem.li5 .mm-listitem__text::before {
	display: block;
	content: "";
	width: 7px;
	height: 11px;
	background: url("../img/icon/arrow/sp_menu_arrow2.webp") no-repeat;
	background-size: contain;
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: 13px;
	margin: auto;
}
.mm-listitem__btn::after {
	border-top: 1px solid #566980;
	border-left: 1px solid #566980;
	width: 10px;
	height: 10px;
	left: 15px;
}
.mm-listitem::after {
	border-bottom: 1px solid #c2ccdd;
	left: 0;
}

.mm-listitem__btn:not(.mm-listitem__text) {
	border: none;
	padding-right: 50px;
}
.mm-listitem__btn:not(.mm-listitem__text)::before {
	display: block;
	content: "";
	width: 1px;
	height: calc(100% - 20px);
	background-color: #c2ccdd;
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: 0;
	margin: auto;
	border: none;
}

.mm-wrapper__blocker {
	display: none;
}

.mm-menu--offcanvas {
	transition: 0.3s cubic-bezier(0.16, 0.96, 0.25, 0.91);
}
.mm-menu--offcanvas:not(.mm-menu--opened) {
	transform: translateX(100%);
}
.mm-menu--offcanvas.mm-menu--opened {
	transform: translateX(0);
}

.drawer__li {
	padding: 0;
}

.drawer__a {
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 30px;
	position: relative;
}
.drawer__a::before {
	display: block;
	content: "";
	width: 19px;
	height: 19px;
	background: url("../img/icon/arrow/sp_menu_arrow.webp") no-repeat;
	background-size: contain;
	position: absolute;
	top: 0;
	right: auto;
	bottom: 0;
	left: 1px;
	margin: auto;
}

.sp_menu_icon_btn_area {
	margin-top: 20px;
	margin-bottom: 25px;
}
.sp_menu_icon_btn_area > .head {
	padding-left: 10px;
}
.sp_menu_icon_btn_area > .head > .jp {
	font-size: 1rem;
	color: #304c70;
	margin-bottom: 5px;
}
.sp_menu_icon_btn_area > .head > .en {
	font-size: 0.875rem;
	color: #304c70;
	font-family: "Source Serif Pro", sans-serif;
	font-weight: normal;
}
.sp_menu_icon_btn_area > .icon_list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.sp_menu_icon_btn_area > .icon_list > .icon_btn {
	width: calc(50% - 10px);
	padding-top: 18px;
	padding-bottom: 18px;
}
.sp_menu_icon_btn_area > .icon_list > .icon_btn:nth-of-type(-n + 4) {
	border-bottom: 1px solid #cfd7e4;
}
.sp_menu_icon_btn_area > .icon_list > .icon_btn .image {
	width: 60px;
	margin: 0 auto 5px;
}
.sp_menu_icon_btn_area > .icon_list > .icon_btn .text {
	color: #475361;
	text-align: center;
	font-size: 0.875rem;
}

.drawer__bottom {
	padding-bottom: 0;
}
.drawer__bottom::after {
	display: none;
}

.drawer__search {
	background-color: #304c70;
	margin: 0 calc(50% - 50vw);
	width: 100vw;
	min-height: 120px;
	padding: 20px;
}
.drawer__search > .search_input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 15px;
}
.drawer__search > .search_input .link {
	color: #fff !important;
}
.drawer__search > .search_input .link:before {
	background: url("../img/icon/arrow/link_arrow_white.webp") no-repeat;
	background-size: contain;
}
.drawer__search > .search_input .input {
	background-color: #223b5c !important;
	box-shadow: inset 3.536px 3.536px 10px 0px rgba(24, 47, 77, 0.5);
	padding-left: 30px;
	color: #fff !important;
}
.drawer__search > .search_input .input::placeholder {
	color: #909cae;
}
.drawer__search > .search_input .submit_btn {
	display: block;
	width: 16px;
	height: 16px;
	background: url("../img/icon/search_gray.webp") no-repeat;
	background-size: contain;
	position: absolute;
	top: -2px;
	right: auto;
	bottom: 0;
	left: 8px;
	margin: auto;
	border: none;
	outline: none;
}

.drawer_language {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #cfd7e4;
	box-sizing: border-box;
	padding: 10px 0px 10px 0px;
}
.drawer_language > .list {
	display: flex;
	width: 100%;
}
.drawer_language > .list .item {
	width: 50%;
	text-align: center;
}
.drawer_language > .list .item:first-child {
	border-right: 1px solid #cfd7e4;
}
.drawer_language > .list .item a {
	display: block;
	padding: 12px 20px;
}
.drawer_language > .list .item .text {
	position: relative;
	padding-right: 18px;
}
.drawer_language > .list .item .text::after {
	display: block;
	content: "";
	width: 6px;
	height: 9px;
	background: url("../img/icon/arrow/link2.webp");
	background-size: contain;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}